// modules
import React, { useState, useEffect } from 'react';
import { FormField, Label, Loader, Search } from 'semantic-ui-react';
import { TAB, useElCatchKey, useNextValue } from '@dometech-group/dometech-packages';

const defaultTitleCallback = data => data.name;
const voidCallback = () => undefined;
const defaultKeyCallback = data => data.id;

const SearchPreviewed = ({
	callback,
	handleLoad,
	loading,
	result,
	error,
	minCharacters = 0,
	label = 'Rechercher',
	voidMessage = 'Aucun résultat correspondant',
	resultKey,
	value,
	defaultValue,
	returnFullObject = false,
	create = false,
	handleWarning,
	preloadTrigger = true,
	titleCallback = defaultTitleCallback,
	keyCallback = defaultKeyCallback,
	extraCallback = voidCallback,
	descriptionCallback = voidCallback,
	name,
	id = `search-field-${name}`, // needed for catch key
	keyReturn = 'id',
	ItemComponent,
	required = false,
	disabled = false,
	placeholder = 'Rechercher',
	...props
}) => {
	const [data, setData] = useState([]);
	const [dataOptions, setDataOptions] = useState([]);

	const handleResult = () => {
		if (result.success) {
			const entities = result[resultKey];
			if (handleWarning && !entities.length && !search) {
				handleWarning(voidMessage);
			}
			if (returnFullObject) {
				setData(
					entities.reduce((res, el) => {
						res[keyCallback(el)] = el;
						return res;
					}, {}) || []
				);
			}
			setDataOptions(
				entities.map(data => ({
					key: keyCallback(data),
					title: titleCallback(data),
					price: extraCallback(data),
					description: descriptionCallback(data),
					[keyReturn]: data[keyReturn],
					value: data,
				})) || []
			);
		}
	};

	// Under const because it use handleResult
	const [search, setSearch] = useNextValue({
		defaultValue: preloadTrigger == null ? null : value,
		handleLoad,
		result,
		loading,
		handleResult,
	});

	// pressing tab choose current result
	const triggerResult = () => {
		const activeEl = document.querySelector(`#${id} .results .result.active`);
		if (activeEl) activeEl.click();
	};
	useElCatchKey({ id, keyCode: TAB, callback: triggerResult });

	useEffect(() => {
		if (preloadTrigger != null) {
			handleLoad(search || undefined);
			setDataOptions([]);
		}
	}, [preloadTrigger]);

	const handleSearch = (e, field) => {
		let search = field.value;
		// trim search value only if value is not controlled
		if (value === undefined) search = search.trim();
		if (search.length >= minCharacters) {
			setSearch(search);
			// send tapped search when search field can create a new entity
			if (create) {
				callback(returnFullObject ? { [keyReturn]: search } : search);
			}
		}
	};

	const handleSelect = (e, selection) => {
		const option = selection.result;
		callback(returnFullObject ? data[option.key] : option[keyReturn]);
	};

	return (
		<FormField
			required={required}
			disabled={disabled}
			className="search-form"
			error={!!error || false}
			id={id}
		>
			{label ? <label>{label}</label> : null}
			<Search
				input={{
					icon: 'search',
					required,
					disabled,
					placeholder,
					...props,
					name,
				}}
				loading={loading}
				onSearchChange={handleSearch}
				onResultSelect={handleSelect}
				results={dataOptions}
				value={value}
				defaultValue={defaultValue}
				noResultsMessage={
					loading ? (
						<>
							<Loader active size="tiny" inline /> Chargement...
						</>
					) : (
						voidMessage
					)
				}
				minCharacters={minCharacters}
				name={name}
				resultRenderer={ItemComponent}
			/>
			{!!error && (
				<Label prompt pointing className="above" role="alert">
					{error}
				</Label>
			)}
		</FormField>
	);
};

export default SearchPreviewed;
