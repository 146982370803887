import React, { useEffect, useState } from 'react';
import { Form, Button, Segment, Icon, Message, Label } from 'semantic-ui-react';
import JournalSelector from '../fields/JournalSelector';
import EntryDetailCollectionForm from './EntryDetailCollectionForm';
import useCreate from '../hooks/fetch/actions/useCreate';
import { deformatDate } from '@dometech-group/dometech-packages';
import AddComment from '../fields/AddComment';
import AttachmentField from '../fields/AttachmentField';
import { TextAreaAutoRows } from '@dometech-group/dometech-packages-ui-semantic';
import useAttachment from '../hooks/form/useAttachment';
import { REQUIRED_MSG } from '../hooks/form/useFormErrors';

const defaultEntry = { entryDetails: [] };
const initEntry = entry => {
	// cannot set account by id here because labels can be defined from details
	return {
		...entry,
		journal: entry.journal.id,
		entryDate: deformatDate(entry.entryDate),
	};
};

const EntryForm = ({ onSuccess, onCancel, initialEntry, loading: loadingBis }) => {
	const [entry, setEntry] = useState(() =>
		initialEntry ? initEntry(initialEntry) : defaultEntry
	);

	const {
		preSuccess,
		attachment,
		handleAttachment,
		loading: loadingAttachment,
	} = useAttachment({
		entityName: 'entry',
		entity: entry,
		onSuccess,
	});

	const { handleSubmit, loading, errors, handleError, handleInvalid, handleClearMsg } =
		useCreate({
			name: 'entry',
			callback: preSuccess,
		});

	// clear errors when entryDetails is updated
	useEffect(() => {
		if (errors.entryUnbalanced) {
			const sum = entry.entryDetails.reduce(
				(sum, detail) => sum + detail.credit - detail.debit,
				0
			);
			if (sum == 0) {
				handleClearMsg('entryUnbalanced');
			}
		}
		if (errors.entryDetails) {
			if (entry.entryDetails.length > 0) {
				handleClearMsg('entryDetails');
			}
		}
	}, [entry.entryDetails]);

	const handleChange = (e, { name, value }) => {
		setEntry(prev => ({ ...prev, [name]: value }));
		handleClearMsg(name);
	};

	const handleJournal = (e, { value }) => {
		setEntry(prev => ({ ...prev, journal: value }));
		handleClearMsg('journal');
	};

	const handleAddEntryDetail = () => {
		setEntry(prev => ({
			...prev,
			entryDetails: [...prev.entryDetails, {}, {}, {}],
		}));
	};

	const handleRemoveDetail = index => {
		const entryDetails = entry.entryDetails.filter((_, i) => i !== index);
		setEntry({ ...entry, entryDetails });
	};

	const handleDetailChange = (index, name, value) => {
		setEntry(prev => {
			const entryDetails = prev.entryDetails.slice();
			const detail = entryDetails[index];
			entryDetails[index] = {
				...detail,
				[name]: typeof value === 'function' ? value(detail) : value,
			};
			return { ...prev, entryDetails };
		});
		handleClearMsg(`entryDetails[${index}].${name}`);
	};

	const handleInvalidDetail = (index, e) => {
		handleError(`entryDetails[${index}].${e.target.name}`, REQUIRED_MSG);
	};

	const onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		if (loading) return;

		handleClearMsg();

		// set entryDetails's account as id, if it has one
		const body = {
			...entry,
			entryDetails: entry.entryDetails.map(detail => ({
				...detail,
				account: detail.account?.id ? detail.account.id : detail.account,
			})),
		};
		handleSubmit(body);
	};

	return (
		<Form
			onSubmit={onSubmit}
			error={!!errors}
			loading={loading || loadingBis || loadingAttachment}
			className="sub-grabble"
		>
			<Form.Group widths="equal" className="sub-grabble">
				<JournalSelector
					value={entry.journal || ''}
					onChange={handleJournal}
					error={errors.journal}
					required
					onInvalid={handleInvalid}
				/>
				<Form.Input
					type="date"
					name="entryDate"
					label="Date"
					value={entry.entryDate || ''}
					onChange={handleChange}
					error={errors.entryDate}
					required
					onInvalid={handleInvalid}
				/>
				{/* <Form.Input
					name="reference"
					label="Référence"
					value={entry.reference || ''}
					onChange={handleChange}
					error={errors.reference}
					onInvalid={handleInvalid}
				/> */}
			</Form.Group>
			<TextAreaAutoRows
				id={`entry-${entry.id || ''}-label`}
				name="label"
				label="Libellé d'écriture"
				value={entry.label || ''}
				onChange={handleChange}
				error={errors.label}
				required
				onInvalid={handleInvalid}
			/>
			<AddComment
				id={`entry-${entry.id || ''}-comment`}
				onChange={handleChange}
				value={entry.comment}
				error={errors.comment}
				onInvalid={handleInvalid}
			/>
			<AttachmentField
				error={errors.attachment}
				attachment={attachment}
				handleError={handleError}
				handleChange={handleAttachment}
			/>
			<Segment className="sub-grabble">
				<h3 className="sub-grabble">Lignes de l'écriture</h3>
				<div className="mb-1">
					<EntryDetailCollectionForm
						name="entryDetails"
						details={entry.entryDetails}
						handleChange={handleDetailChange}
						handleRemove={handleRemoveDetail}
						handleInvalid={handleInvalidDetail}
						errors={errors.entryDetails}
						className="m-0"
						handleClearMsg={handleClearMsg}
					/>
				</div>
				{!!errors.entryUnbalanced && (
					<Label
						basic
						color="red"
						prompt
						pointing="above"
						className="mb-1"
						style={{ marginTop: '-1rem' }}
						content={errors.entryUnbalanced}
					/>
				)}
				{typeof errors.entryDetails === 'string' && (
					<Label
						basic
						color="red"
						prompt
						pointing="above"
						className="mb-1"
						style={{ marginTop: '-1rem' }}
						content={errors.entryDetails}
					/>
				)}
				<div className="pr-0_5 pl-0_5 sub-grabble">
					<Button type="button" primary basic onClick={handleAddEntryDetail}>
						<Icon name="plus" /> Ajouter 3 lignes
					</Button>
				</div>
			</Segment>
			<div className="flex flex-wrap justify-content-end sub-grabble">
				{onCancel ? (
					<Button type="button" loading={loading} onClick={onCancel}>
						Annuler
					</Button>
				) : null}
				<Button primary type="submit" loading={loading}>
					Valider
				</Button>
			</div>
		</Form>
	);
};

export default EntryForm;
