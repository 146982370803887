import { Query } from '@testing-library/react';
import { createContext } from 'react';

export interface LoadContextInterface {
	loading: boolean;
	reload?: CallableFunction;
	query?: Query;
}

const defaultValue: LoadContextInterface = {
	loading: false,
	reload: undefined,
	query: undefined,
};
export const LoadContext = createContext<LoadContextInterface>(defaultValue);
