import React, { useMemo, useState } from 'react';
import { Button, FormInput, Icon, Label, Message, Table } from 'semantic-ui-react';
import SearchAccount from '../fields/SearchAccount';
import {
	InputNumberControlled,
	TableLayout,
} from '@dometech-group/dometech-packages-ui-semantic';
import { defaultObject, formatPrices } from '@dometech-group/dometech-packages';

const COLS = [
	{
		key: 'number',
		content: (
			<>
				Numéro de Compte <span className="text-red">*</span>
			</>
		),
	},
	{
		key: 'label',
		content: (
			<>
				Libellé de Compte <span className="text-red">*</span>
			</>
		),
	},
	{ key: 'debit', content: 'Débit' },
	{ key: 'credit', content: 'Crédit' },
	{ key: 'action' },
];
const EntryDetailCollectionForm = ({
	details = [],
	handleChange,
	handleInvalid,
	errors = defaultObject,
	handleRemove,
}) => {
	const [solde, sumDebit, sumCredit] = useMemo(
		() =>
			details.reduce((acc, detail) => {
				acc[1] = (acc[1] || 0) + (detail.debit || 0);
				acc[2] = (acc[2] || 0) + (detail.credit || 0);
				acc[0] = acc[2] - acc[1];
				return acc;
			}, []),
		[details]
	);

	if (!details.length) return null;
	return (
		<TableLayout cols={COLS} multiple className="overflow-visible">
			{details.map((detail, i) => (
				<EntryDetailRow
					key={detail.id || i}
					detail={detail}
					handleChange={(name, value) => handleChange(i, name, value)}
					handleRemove={() => handleRemove(i)}
					handleInvalid={e => handleInvalid(i, e)}
					errors={errors[i]}
				/>
			))}
			<Table.Footer>
				<Table.Row>
					<Table.Cell textAlign="center">Solde</Table.Cell>
					<Table.Cell textAlign="right">{formatPrices(solde)}</Table.Cell>
					<Table.Cell textAlign="right">{formatPrices(sumDebit)}</Table.Cell>
					<Table.Cell textAlign="right">{formatPrices(sumCredit)}</Table.Cell>
					<Table.Cell></Table.Cell>
					{/* <Table.Cell></Table.Cell> */}
				</Table.Row>
			</Table.Footer>
		</TableLayout>
	);
};

const EntryDetailRow = ({
	detail,
	handleChange,
	handleRemove,
	errors = defaultObject,
	handleInvalid,
}) => {
	const [addingLabel, setAddingLabel] = useState(false);
	const isEmpty = !(
		(detail.account && (detail.account.number || detail.account.label)) ||
		detail.credit ||
		detail.debit ||
		detail.label
	);

	const onChange = (e, { name, value }) => handleChange(name, value);
	const onChangeAmount = (e, { name, value }) => {
		if (name === 'debit') {
			handleChange('credit', null);
		} else if (name === 'credit') {
			handleChange('debit', null);
		}
		handleChange(name, value == '' ? value : value);
	};

	const handleChangeAccount = account => {
		handleChange('account', detail => {
			if (account.id) {
				return account;
			}
			if (account.number && account.number != detail.account?.number) {
				return account;
			}
			return { ...detail.account, ...account };
		});
	};

	const handleInvalidBuffer = e => {
		// don't check if empty
		if (!isEmpty) {
			handleInvalid(e);
		}
	};

	return (
		<Table.Body>
			<Table.Row>
				<Table.Cell>
					<SearchAccount
						label={null}
						name="account.number"
						keyName="number"
						account={detail.account}
						callback={handleChangeAccount}
						error={errors.account}
						required={!isEmpty}
						onInvalid={handleInvalidBuffer}
					/>
				</Table.Cell>
				<Table.Cell>
					<SearchAccount
						label={null}
						name="account.label"
						keyName="label"
						account={detail.account}
						callback={handleChangeAccount}
						error={errors.account}
						required={!isEmpty}
						onInvalid={handleInvalidBuffer}
					/>
				</Table.Cell>
				<Table.Cell>
					<InputNumberControlled
						name="debit"
						// label="Débit"
						price
						value={detail.debit}
						onChange={onChangeAmount}
						error={errors.debit}
						onInvalid={handleInvalidBuffer}
					/>
				</Table.Cell>
				<Table.Cell>
					<InputNumberControlled
						name="credit"
						// label="Crédit"
						price
						value={detail.credit}
						onChange={onChangeAmount}
						error={errors.credit}
						onInvalid={handleInvalidBuffer}
					/>
				</Table.Cell>
				<Table.Cell className="white-space-nowrap" rowSpan={addingLabel ? 2 : 1}>
					<Button type="button" icon="trash" onClick={handleRemove} />{' '}
					{!addingLabel && (
						<Button
							type="button"
							basic
							primary
							className="icon"
							title="Ajouter un libellé"
							onClick={() => setAddingLabel(true)}
						>
							<Icon.Group>
								<Icon name="align left" flipped="vertically" />
								<Icon corner name="plus" />
							</Icon.Group>
						</Button>
					)}
				</Table.Cell>
			</Table.Row>
			{addingLabel && (
				<Table.Row className="tr-merged">
					<Table.Cell colSpan={4}>
						<FormInput
							name="label"
							labelPosition="right"
							value={detail.label || ''}
							onChange={onChange}
							error={errors.label}
							onInvalid={handleInvalidBuffer}
						>
							<Label basic>Libellé de ligne</Label>
							<input />
							<Button
								className="label"
								type="button"
								icon={{ name: 'close', className: 'm-0' }}
								onClick={() => {
									setAddingLabel(false);
									handleChange('label', null);
								}}
							/>
						</FormInput>
					</Table.Cell>
				</Table.Row>
			)}
			{!!errors.amount && (
				<Table.Row className="tr-merged">
					<Table.Cell className="pt-0" colSpan={5}>
						<Label basic color="red" content={errors.amount} />
					</Table.Cell>
				</Table.Row>
			)}
		</Table.Body>
	);
};

export default EntryDetailCollectionForm;
